import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';
import GTMData from '@square-enix-private/fe-gtm/dist/GTMData';
import { YouTube } from '@square-enix-private/fe-media';

import { Flex, Heading } from '@square-enix-private/vaquita-ui';
import { SEHeaderHeight } from '~/constants/site';
import SectionTitle from '~/components/SectionTitle/index';
import {
  MainVideo,
  ItemWrapper,
  VideoContainer,
  VideoItem,
  CloseButtonWrapper,
  CloseViewer
} from '~/components/VideosListViewer/elements';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const BroadcastVideoPage = ({
  pageContext: { locale },
  data: { broadcastItem }
}) => {
  const sectionTitle = useTranslation('broadcastpage').t('title');
  const content = broadcastItem[0];
  const { related, title, video } = content;
  const relatedItems = related || [];

  return (
    <Flex
      width={1}
      maxWidth="1400px"
      minHeight={`calc(100vh - ${SEHeaderHeight}px)`}
      m="auto"
      alignItems="center"
      flexDirection="column"
      pt={['xlarge', 'xxlarge']}
      pb={[null, null, 'xlarge']}
      px={['medium', 'large']}
    >
      <Flex width={[1]} flexDirection={['column', null, 'row']}>
        <Flex
          width={[1]}
          justifyContent={['center', 'left']}
          alignItems="center"
          flexDirection={['column', 'row']}
        >
          <SectionTitle
            mx={['auto', 0]}
            width={[1, null, 'unset']}
            title={sectionTitle}
          />
          <CloseButtonWrapper mr="medium" ml="auto" mt={['xlarge', 'unset']}>
            <CloseViewer
              height={40}
              maxWidth={40}
              variant="secondary"
              to={`/${locale}/broadcast`}
            >
              <FontAwesomeIcon size="2x" icon={faTimes} />
            </CloseViewer>
          </CloseButtonWrapper>
        </Flex>
      </Flex>
      <MainVideo width={1} mt="large" mb={['xlarge', 0]}>
        <VideoContainer width={1}>
          <VideoItem width={1}>
            <YouTube videoId={video.videoId} width={1} height="100%" />
          </VideoItem>
          <Heading textAlign="center" m="0" my="medium">
            {title}
          </Heading>
        </VideoContainer>
      </MainVideo>
      {relatedItems.length > 0 && (
        <Flex
          width={1}
          flexDirection={['column', 'unset']}
          mt={[null, 'xlarge']}
          mb={['large', 'xlarge']}
        >
          {relatedItems.map(({ id, title, videoId }) => (
            <ItemWrapper
              key={id}
              width={[1, 1 / 3, 1]}
              mx={[null, 'small', 'medium']}
              mt={[null, 'medium']}
              mb="xlarge"
            >
              <VideoContainer width={1}>
                <VideoItem width={1}>
                  <YouTube videoId={videoId} width={1} height="100%" />
                </VideoItem>
                <Heading textAlign="center" m="0" my="medium">
                  {title.toUpperCase()}
                </Heading>
              </VideoContainer>
            </ItemWrapper>
          ))}
        </Flex>
      )}
      <GTMData pageId="broadcastPage" breadcrumb="broadcastPage" />
    </Flex>
  );
};

BroadcastVideoPage.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object
};

BroadcastVideoPage.defaultProps = {
  data: {},
  pageContext: {}
};

export const query = graphql`
  query($locale: String, $slug: String) {
    ...FooterData
    ...GenericData
    ...MetadataData
    ...FlagsData
    ...MenuData
    ...AssetsData
    ...AgeGateData
    broadcastpage(filter: { localeDirectory: { eq: $locale } }) {
      title
    }
    broadcastItem: broadcastitems(
      filter: { localeDirectory: { eq: $locale }, slug: { eq: $slug } }
    ) {
      slug
      title
      id
      video {
        title
        videoId
      }
      video {
        alt
        image {
          ...FastlyProps
        }
      }
      related {
        title
        videoId
        id
      }
    }
  }
`;

export default BroadcastVideoPage;
